/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable linebreak-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InstagramIcon from '@material-ui/icons/Instagram';
import * as appActions from '../../store/app/actions';
import * as accountActions from '../../store/account/actions';
import * as cartActions from '../../store/cart/actions';

// import FacebookIcon from '@material-ui/icons/Facebook';

import { history } from '../../helpers/history';
import UserMenu from '../UserMenu';

import PhonePopUp from '../PhonePopUp';
import Modal from '../Modal';

import bigLogo from '../../assets/images/logotipo-unindo-sonhos.png';
import smallLogo from '../../assets/images/logotipo-unindo-sonhos-small.png';

import './style.scss';
import MobileCart from '../MobileCart';
import Timer from '../Timer';
import { CartText } from '../Cart/tooltipText';
import MUSIcon from '../../assets/images/MUS.svg';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      logo: bigLogo,
      openLogin: false,
      menuMobile: false,

      bankAccount: {},
    };

    this.numItems = this.numItems.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 100) { this.setState({ ...this.state, scrolled: true }); } else { this.setState({ ...this.state, scrolled: false }); }
    });

    const storage = JSON.parse(localStorage.getItem('unindo_sonhos_data'));
    if ((storage && storage.token) && !this.props.userData.id) {
      await this.props.accountActions.me();
    }
  }

  componentDidUpdate() {
    this.props.appData.mobileMenu
      ? document.body.classList.add('no-scroll-menu')
      : document.body.classList.remove('no-scroll-menu');
  }

  numItems() {
    let numItems = 0;
    const { items } = this.props.cartData;
    items?.length > 0 && items.map((item) => numItems += item.qty);
    return numItems;
  }

  render() {
    return (
      <header id="top-menu">
        {/* <a target="_blank" href={process.env.REACT_APP_PLAY_URL || 'https://play.unindosonhos.com.br'} className="banner-mus" rel="noreferrer"> */}
        {/*  <img src={MUSIcon} width={40} height={40} alt="Moeda MUS" /> */}
        {/*  <p> */}
        {/*    Compre pelo */}
        {/*    {' '} */}
        {/*    <strong>aplicativo</strong> */}
        {/*    {' '} */}
        {/*    e ganhe nossa */}
        {/*    {' '} */}
        {/*    <strong>moeda virtual</strong> */}
        {/*    ! O */}
        {/*    {' '} */}
        {/*    <strong>MUS</strong> */}
        {/*    ! */}
        {/*  </p> */}
        {/*  <p className="mus-arrow"> */}
        {/*    CONFIRA ➜➜➜ */}
        {/*  </p> */}
        {/* </a> */}
        <MobileCart cartProps={this.props} />
        <section id="top-bar-desktop" className={`desktop ${this.state.scrolled ? 'small' : null}`}>
          <div className="container">
            <div className="row">
              <figure onClick={() => history.push('/')}>
                <img src={smallLogo} alt="Logotipo da Unindo Sonhos" className={`${!this.state.scrolled ? 'hidden' : null}`} />
                <img src={bigLogo} alt="Logotipo da Unindo Sonhos" className={`${this.state.scrolled ? 'hidden' : null}`} />
              </figure>

              <nav>
                <ul>
                  {/* <li><NavLink to="/comprar-creditos" activeClassName="active">Comprar créditos</NavLink></li> */}
                  <li><NavLink to="/boloes" activeClassName="active">Bolões</NavLink></li>
                  <li><NavLink to="/quem-somos" activeClassName="active">Quem somos</NavLink></li>
                  <li><NavLink to="/como-funciona" activeClassName="active">Como funciona</NavLink></li>
                </ul>
              </nav>

              <div>
                <UserMenu />
                <span
                  className="cart"
                  onClick={() => {
                    this.props.appActions.handleCart();
                  }}
                >
                  <ShoppingCartIcon />
                  <span className="quantity"><small>{this.numItems() > 99 ? '99+' : this.numItems()}</small></span>
                </span>
                <Timer
                  key="cart-timer"
                  tooltip={CartText}
                  tooltipPosition="bottomEnd"
                  onlyText
                  refreshReduxCartExpiration
                  cart={this.props.cartData}
                  deadline={this.props.cartData.expiry_seconds_at}
                  onEnd={() => {
                    this.props.cartActions.clearAllCarts();
                    this.props.cartActions.expireCart();
                    this.props.appActions.handleCart();
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="top-bar-mobile" className="mobile">
          <div className="container">
            <div className="row">

              <div
                id="sandwich"
                className={this.props.appData.mobileMenu ? 'change' : null}
                onClick={() => this.props.appActions.handleMobileMenu()}
              >
                <div className="bar-one" />
                <div className="bar-two" />
                <div className="bar-three" />
              </div>

              <figure onClick={() => history.push('/')}>
                <img
                  src={bigLogo}
                  alt="Logotipo da Unindo Sonhos"
                />
              </figure>

              <span className="cart" onClick={() => this.props.appActions.handleCart()}>
                <ShoppingCartIcon />
                <span className="quantity"><small>{this.numItems() > 99 ? '99+' : this.numItems()}</small></span>
              </span>

            </div>
          </div>
        </section>

        <section id="menu-mobile" className={`animated faster ${this.props.appData.mobileMenu ? 'opened fadeInLeft' : 'closed fadeOutLeft'}`}>
          <UserMenu />

          <nav>
            <ul>
              <li><NavLink to="/" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Início</NavLink></li>
              <li><NavLink to="/boloes" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Bolões</NavLink></li>
              {/* <li><NavLink to="/comprar-creditos" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Comprar créditos</NavLink></li> */}
              <li><NavLink to="/quem-somos" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Quem somos</NavLink></li>
              <li><NavLink to="/como-funciona" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Como funciona</NavLink></li>
              <li><NavLink to="/perguntas-frequentes" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Perguntas Frequentes</NavLink></li>
              <li><NavLink to="/fale-conosco" onClick={() => this.props.appActions.handleMobileMenu()} activeClassName="active">Fale conosco</NavLink></li>
            </ul>
          </nav>

          <div className="social">
            {/* <Link href="https://facebook.com"  target="_blank"><FacebookIcon/></Link> */}
            <Link href="https://www.instagram.com/unindosonhoss/" target="_blank"><InstagramIcon /></Link>
          </div>
        </section>
        {Object.keys(this.props.userData).length !== 0 && (!this.props.userData.phone || this.props.userData.phone === '') ? <PhonePopUp /> : ''}
        <Modal title="Aviso" subtitle="A Unindo Sonhos não possui qualquer vínculo com a Caixa Econômica Federal. Somos uma empresa independente que faz o trabalho de intermediação e representação. Todos os bolões aqui ofertados devem e serão validados em Lotérica. Todos os direitos reservados à Caixa Econômica Federal." buttonText="Fechar" />
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  appData: state.appReducer,
  userData: state.accountReducer.userData,
  cartData: state.cartReducer,
});
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
  cartActions: bindActionCreators(cartActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
