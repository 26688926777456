/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { Fragment, useEffect } from 'react';
import {
  Router, Route, Switch, useLocation,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
import { history } from './helpers/history';
import ScrollToTop from './helpers/scrollToTop';

import PrivateRoute from './components/PrivateRoute';
import Menu from './components/Menu';
import Footer from './components/Footer';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
// import Register from './pages/Register';
import BuyCredits from './pages/BuyCredits';
import RedirectPage from './pages/Redirect';
// import BuyCreditsDisabled from './pages/BuyCreditsDisabled';
import AcceptTerms from './pages/AcceptTerms';
import Jackpots from './pages/Jackpots';
import About from './pages/About';
import HowWork from './pages/HowWork';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import TermsAndConditions from './pages/TermsAndConditions';
import LGPD from './pages/LGPD';
import ForgotPassword from './pages/ForgotPassword';
import RescuePassword from './pages/RescuePassword';
import CheckoutResult from './pages/Checkout/success';
import { Checkout } from './pages/Checkout';
import Index from './pages/Manutencao';
import Manutencao from './pages/Manutencao';

function Routes() {
  const [cookies, setCookie] = useCookies(['ref']);
  const refParamURL = new URLSearchParams(window.location.search).get('ref');

  useEffect(() => {
    if (!cookies.ref && refParamURL) {
      setCookie('ref', refParamURL, {
        maxAge: 24 * 60 * 60,
      });
    }
  }, []);

  return (
    <Router history={history}>
      <ScrollToTop />
      <Route render={
        ({ location }) => {
          if (process.env.REACT_APP_MAINTENANCE === 'true') {
            return <Route from="*" component={Manutencao} />;
          }

          if (window.location.href.indexOf('/dashboard') !== -1) {
            window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/dashboard`;
          }

          return (
            <>
              {
              location.pathname !== '/cadastro'
                                && !location.pathname.includes('/checkout')
                                && location.pathname !== '/esqueci-minha-senha'
                                && window.location.href.indexOf('/redirect') === -1
                                && window.location.href.indexOf('/dashboard') === -1
                                && !location.pathname.includes('/recuperar-senha')
                ? <Menu />
                : null
            }
              <TransitionGroup>
                <CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }} classNames="fade">
                  <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/redirect" component={RedirectPage} exact />
                    {/* <Route path="/cadastro" component={Register} exact /> */}

                    {/* <Route path="/comprar-creditos" component={BuyCredits} /> */}
                    <Route path="/boloes/:bolao" component={Jackpots} />
                    <Route path="/boloes" component={Jackpots} />
                    <Route path="/quem-somos" component={About} />
                    <Route path="/como-funciona" component={HowWork} />
                    <Route path="/fale-conosco" component={Contact} />
                    <Route path="/perguntas-frequentes" component={Faq} />
                    <Route path="/termos-e-condicoes" component={TermsAndConditions} />
                    <Route path="/termo-de-consentimento-para-tratamento-de-dados" component={LGPD} />
                    <Route path="/aceite-termos" component={AcceptTerms} />

                    <Route path="/esqueci-minha-senha" component={ForgotPassword} />
                    <Route path="/recuperar-senha/:token" component={RescuePassword} />

                    {/* <PrivateRoute path="/cadastro/:step" component={Register} /> */}

                    {/* <PrivateRoute path="/dashboard" component={Dashboard} exact /> */}
                    {/* <PrivateRoute path="/dashboard/meu-cadastro" component={UserAccount} /> */}
                    {/* <PrivateRoute path="/dashboard/meu-cadastro-conta-bancaria" component={UserBankAccount} exact /> */}
                    {/* <PrivateRoute path="/dashboard/security" component={Security} exact /> */}
                    {/* <PrivateRoute path="/dashboard/security/password" component={Password} exact /> */}
                    {/* <PrivateRoute path="/dashboard/security/email" component={Email} exact /> */}
                    {/* <PrivateRoute path="/dashboard/security/phone" component={Phone} exact /> */}
                    {/* <PrivateRoute path="/dashboard/carteira-do-cliente" component={UserWallet} /> */}
                    {/* <PrivateRoute path="/dashboard/minhas-compras" component={UserOrders} /> */}
                    {/* <PrivateRoute path="/dashboard/minhas-apostas" component={Bets} /> */}
                    {/* <PrivateRoute path="/dashboard/fazer-transferencia" component={Transfer} /> */}

                    <PrivateRoute path="/checkout/:id" component={CheckoutResult} exact />
                    <PrivateRoute path="/checkout" component={Checkout} exact />
                    <PrivateRoute path="/dashboard" component={() => (null)} exact />
                    <PrivateRoute path="/dashboard/meu-cadastro" component={() => (null)} />
                    <PrivateRoute path="/dashboard/meu-cadastro-conta-bancaria" component={() => (null)} exact />
                    <PrivateRoute path="/dashboard/security" component={() => (null)} exact />
                    <PrivateRoute path="/dashboard/security/password" component={() => (null)} exact />
                    <PrivateRoute path="/dashboard/security/email" component={() => (null)} exact />
                    <PrivateRoute path="/dashboard/security/phone" component={() => (null)} exact />
                    <PrivateRoute path="/dashboard/carteira-do-cliente" component={() => (null)} />
                    <PrivateRoute path="/dashboard/minhas-compras" component={() => (null)} />
                    <PrivateRoute path="/dashboard/minhas-apostas" component={() => (null)} />
                    <PrivateRoute path="/dashboard/fazer-transferencia" component={() => (null)} />

                    <Route from="*" component={NotFound} exact />

                  </Switch>
                </CSSTransition>
              </TransitionGroup>
              {
              location.pathname !== '/cadastro'
                                && !location.pathname.includes('/checkout')
                                && location.pathname !== '/esqueci-minha-senha'
                                && window.location.href.indexOf('/redirect') === -1
                                && window.location.href.indexOf('/dashboard') === -1
                                && !location.pathname.includes('/recuperar-senha')
                ? <Footer />
                : null
            }
            </>
          );
        }
      }
      />
    </Router>
  );
}

export default Routes;
