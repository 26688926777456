import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { history } from '../../helpers/history';

import jackpotsSevices from '../../services/jackpots';
import JackpotBox from '../../components/JackpotBox';
import Button from '../../components/Button';
import Filter from '../../components/Filter';

import './style.scss';

class Jackpots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jackpots: [],
      filter: {},
      pagination: {},

      loading: false,
      finished: true,
    };

    this.doFilter = this.doFilter.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  async componentDidMount() {
    this.setState({ ...this.state, loading: true });
    const { data } = await jackpotsSevices.getJackpots({ status: 'production', concourse_id: this.props.concourse_id });
    await this.setState({
      ...this.state,
      jackpots: data && data.result ? data.result : [],
      pagination: data && data.pagination ? data.pagination : {},
      finished: !!(data && data.pagination.current === data.pagination.total_pages),
    });
    this.setState({ ...this.state, loading: false });
  }

  async doFilter(date, range, type = null) {
    this.setState({ ...this.state, loading: true });
    const { data } = await jackpotsSevices.getJackpots({
      date, range, type, status: 'production', concourse_id: this.props.concourse_id,
    });
    const { pagination, result } = data;

    this.setState({
      ...this.state,
      jackpots: result || this.state.jackports,
      pagination: pagination || this.state.pagination,
      filter: { drawDate: date, range, type },
      finished: !!((pagination && (pagination.current === pagination.total_pages))),
    });
    this.setState({ ...this.state, loading: false });
  }

  async loadMore() {
    this.setState({ ...this.state, loading: true });
    const { pagination, filter } = this.state;

    const _params = {
      drawDate: filter.date,
      range: filter.range,
      type: filter.type,
      page: pagination.next,
      concourse_id: this.props.concourse_id,
      status: 'production',
    };

    const { data } = await jackpotsSevices.getJackpots(_params);
    const _array = this.state.jackpots;

    if (data.result.length) { await data.result.map((item) => _array.push(item)); }

    await this.setState({
      ...this.state,
      finished: !(data.pagination.next),
      loading: false,
      pagination: data.pagination,
      jackpots: _array,
    });
    this.setState({ ...this.state, loading: false });
  }

  render() {
    const { jackpots, finished, loading } = this.state;
    const { total } = this.state.pagination;

    return (
      <>
        <Filter action={this.doFilter} totalRows={total} />

        <section id="jackpots">
          <div className="list">
            <div className="container">

              <div className="row">
                {
                  jackpots && jackpots.length >= 1
                    ? jackpots.map((item, index) => ((parseInt(item.shares_available_quantity) > 0) ? <JackpotBox jackpot={item} key={index} /> : null))
                    : (<span className="empty">Nesse momento os bolões estão esgotados. Muito em breve novos bolões estarão disponíveis!</span>)
                }
              </div>

              <span className="pagination">
                {!finished && <Button type="button" color="green invert" label="Carregar mais bolões" action={this.loadMore} />}
              </span>

              <div className="buy-credits">
                <span>Compre créditos e jogue agora!</span>
                {/* <Button color="lemon" action={(event) => history.push('/comprar-creditos')} label="Comprar Créditos" /> */}
              </div>

              {loading && <span className="loading"><CircularProgress /></span>}

            </div>
          </div>
        </section>

      </>
    );
  }
}
export default Jackpots;
