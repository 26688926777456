import React from 'react';
import { NavLink } from 'react-router-dom';

// import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from '@material-ui/core/Link';
import { history } from '../../helpers/history';

import Button from '../Button';

import smallLogo from '../../assets/images/logotipo-unindo-sonhos-small.png';
import './style.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">

            <div className="logo" onClick={(event) => history.push('/')}>
              <img src={smallLogo} alt="Logotipo da Unindo Sonhos" />
            </div>

            <nav>
              <ul>
                <li><NavLink to="/boloes">Bolões</NavLink></li>
                <li><NavLink to="/perguntas-frequentes">Perguntas frequentes</NavLink></li>
                <li><NavLink to="/fale-conosco">Fale conosco</NavLink></li>
                <li><NavLink to="/termos-e-condicoes">Termos e condições</NavLink></li>
                <li><NavLink to="/termo-de-consentimento-para-tratamento-de-dados">Segurança dos dados</NavLink></li>
              </ul>
            </nav>

            {/* <div className="actions"> */}
            {/*  <Button type="button" color="green" label="Comprar crédito" action={(event) => history.push('/comprar-creditos')} /> */}
            {/* </div> */}

            <div className="social">
              {/* <Link href="https://facebook.com"  target="_blank"><FacebookIcon/></Link> */}
              <Link href="https://www.instagram.com/unindosonhoss/" target="_blank"><InstagramIcon /></Link>
            </div>

          </div>
        </div>

      </footer>
    );
  }
}

export default Footer;
